<template>
  <b-card>
    <h4 class="mb-0">
      Detail Payment Setting
    </h4>
    <validation-observer ref="validUpdate">
      <b-form class="mt-1" @submit.prevent="validationForm">
        <b-row>
          <b-col md="6">
            <b-form-group label="Jenis Pembayaran" label-for="payment_type">
              <validation-provider #default="{ errors }" name="payment_type" rules="required">
                <v-select id="payment_type" v-model="dataStudent.payment_type" :reduce="(payment_type) => payment_type.id"
                  :options="listpaymenttype" label="name" placeholder="Pilih Jenis Pembayaran" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Tahun Akademik" label-for="academic_year_id">
              <validation-provider #default="{ errors }" name="academic_year_id" rules="required">
                <v-select id="academic_year_id" v-model="dataStudent.academic_year_id"
                  :reduce="(academic_year_id) => academic_year_id.id" :options="listacademicyear" label="name"
                  placeholder="Pilih Tahun" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Kelas" label-for="class_id">
              <validation-provider #default="{ errors }" name="class_id" rules="required">
                <v-select id="class_id" v-model="dataStudent.class_id" :reduce="(class_id) => class_id.id"
                  :options="listclass" label="level_desc" placeholder="Pilih Kelas" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Nominal" label-for="nominal">
              <validation-provider #default="{ errors }" name="name" rules="required">
                <b-form-input id="nominal" v-model="dataStudent.nominal" :state="errors.length > 0 ? false : null"
                  placeholder="Nominal" type="number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Periode" label-for="period">
              <validation-provider #default="{ errors }" name="period" rules="required">
                <v-select id="period" v-model="dataStudent.period" :reduce="(period) => period.text"
                  :options="dataPeriode" label="text" placeholder="Pilih Aktif / Tidak Aktif" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Notes" label-for="notes">
              <validation-provider #default="{ errors }" name="notes" rules="required">
                <b-form-input id="notes" v-model="dataStudent.notes" :state="errors.length > 0 ? false : null"
                  placeholder="Notes" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Status" label-for="active">
              <validation-provider #default="{ errors }" name="active" rules="required">
                <v-select id="active" v-model="dataStudent.active" :reduce="(active) => active.text"
                  :options="dataIsActive" label="text" placeholder="Pilih Aktif / Tidak Aktif" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mr-1"
              @click="() => $router.go(-1)">
              Cancel
            </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary">
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  // BContainer,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BFormInvalidFeedback,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'

export default {
  name: 'DataSiswaEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BContainer,
    // BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    required,
    dataStudent: {
      payment_type: '',
      academic_year_id: '',
      class_id: '',
      nominal: '',
      period: '',
      active: '',
      notes: '',
    },
    dataIsActive: [
      { value: 1, text: 'Aktif' },
      { value: 0, text: 'Tidak Aktif' },
    ],
    dataPeriode: [
      { value: 'Bulanan', text: 'Bulanan' },
      { value: 'Tahunan', text: 'Tahunan' },
    ],
    listpaymenttype: [],
    listclass: [],
    listacademicyear: [],
  }),
  computed: {

  },
  created() {
    this.getStudentDetail()
    this.getpaymenttype(),
      this.getclass(),
      this.getacademicyear()
  },
  methods: {
    async getStudentDetail() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/paymentsettings/${studentId}`)
        this.fillStudentData(response.data.data)
      } catch (err) {
        console.log(err)
      }
    },
    async getpaymenttype() {
      try {
        const response = await this.$http.get('/paymentmastertypes')
        this.listpaymenttype = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getclass() {
      try {
        const response = await this.$http.get('/levels')
        this.listclass = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getacademicyear() {
      try {
        const response = await this.$http.get('/academicyears')
        this.listacademicyear = response.data.data
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    fillStudentData(data) {
      this.dataStudent.payment_type = data.payment_type
      this.dataStudent.class_id = data.class_id
      this.dataStudent.nominal = data.nominal
      this.dataStudent.period = data.period
      this.dataStudent.active = data.active
      this.dataStudent.notes = data.notes
      this.dataStudent.academic_year_id = data.academic_year_id
    },
    validationForm() {
      this.$refs.validUpdate.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line
          try {
            const studentId = this.$route.params.id
            const dataFormStudent = new FormData()
            Object.keys(this.dataStudent).forEach(key => {
              dataFormStudent.append(key, this.dataStudent[key])
            })
            dataFormStudent.append('_method', 'PUT')
            await this.$http.post(`/paymentsettings/${studentId}`, dataFormStudent, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Data Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'setting-pembayaran' })
          } catch (err) {
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Data Tidak Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            console.log(err)
          }
        }

        // function alertnya() {
        //   alert('form submitted!')
        // }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
